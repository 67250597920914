<template>
  <!--Button and dialog to show the table-->
  <v-container
    :style="$vuetify.breakpoint.mdOnly ? 'max-width:1000px' : ''"
    class="pb-0 px-0"
  >
    <!--TOTAL CLIENTS-->
    <v-row>
      <v-col cols="12" sm="6" md="3">
        <v-card raised elevation="6" class="mt-1">
          <v-card-title>
            <h4
              style="
                font-size: 15px;
                text-transform: uppercase;
                font-weight: 100;
                text-decoration: underline;
              "
            >
              {{ $t("gastos totales") }}
            </h4>
          </v-card-title>
          <v-simple-table style="background-color: #1e1e1e">
            <h1 v-if="totalExpenses == 0">-</h1>
            <h1 v-else class="pt-md-6">{{ $n(totalExpenses) }}€</h1>
          </v-simple-table>
        </v-card>
      </v-col>
      <v-col cols="4">
        <ExpensesBySuppliers />
      </v-col>

      <v-col cols="4">
        <ExpensesByInternUses />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "ExpensesTable",
  components: {
    ExpensesBySuppliers: () =>
      import("@/components/stats/expenses/ExpensesBySuppliers"),
    ExpensesByInternUses: () =>
      import("@/components/stats/expenses/ExpensesByInternUses"),
  },
  data() {
    return {
      dialog: true,
      totalExpenses: 0,
    };
  },
  mounted() {
    this.reload();
  },
  watch: {
    filters: {
      handler() {
        this.reload();
      },
      deep: true,
    },
  },
  computed: {
    ...mapState("stats", ["filters"]),
  },
  methods: {
    ...mapActions("stats", ["getExpenses"]),

    reload() {
      this.fetchExpenses();
    },
    ...mapMutations("stats", ["SET_LIST"]),

    fetchExpenses() {
      this.getExpenses({
        paginate: {},
        filters: {},
      }).then((expenses) => {
        this.totalExpenses = -1 * expenses.totalExpenses;
        console.log(this.totalExpenses);
        this.SET_LIST({
          value: expenses.bySuppliers,
          list: "bySuppliers",
        });
        this.SET_LIST({
          value: expenses.byInternUses,
          list: "byInternUses",
        });
      });
    },
  },
};
</script>
<style></style>
